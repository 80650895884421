import { Theme } from '@app/shared/models/theme';
import { DomainSettings } from '@app/shared/models/domainSettings';
import { GetRecordOutput } from '@smash-sdk/domain/ejs/01-2024';
import { environment } from './environments/environment';
import { ConnectionAbortedError, NetworkError, TimeoutError } from '@smash-sdk/core';
import { ErrorRetryConfiguration } from '@app/shared/operators/genericRetry';
import { ListCountriesOutput } from '@smash-sdk/vat/types/10-2019/types/ListCountries/ListCountries';

export class Constant {
    public static RETRY_DELAY_SDK_NETWORK_ERROR = 1000;
    public static RETRY_DELAY_SDK_CONNECTION_ABORTED_ERROR = 1000;
    public static RETRY_DELAY_SDK_TIMEOUT_ERROR = 1000;

    public static NetworkErrorsRetryConfiguration: ErrorRetryConfiguration[] = [
        { error: NetworkError, delay: Constant.RETRY_DELAY_SDK_NETWORK_ERROR, maxRetry: Infinity },
        { error: ConnectionAbortedError, delay: Constant.RETRY_DELAY_SDK_CONNECTION_ABORTED_ERROR, maxRetry: Infinity },
        { error: TimeoutError, delay: Constant.RETRY_DELAY_SDK_TIMEOUT_ERROR, maxRetry: Infinity },
    ];

    public static resourceTagKeys = {
        applicationID: 'applicationID',
        version: 'version',
    };

    public static getMoreFilesLimit = {
        desktop: 9,
        tablet: 10,
    }

    public static transferStatusTranslations = {
        Uploaded: 'PREMIUM_TRANSFER_SENT_DETAILS_TRANSFER_STATUS_UPLOADED',
        Uploading: 'PREMIUM_TRANSFER_SENT_DETAILS_TRANSFER_STATUS_UPLOADING',
        Deleted: 'PREMIUM_TRANSFER_SENT_DETAILS_TRANSFER_STATUS_DELETED',
        Deleting: 'PREMIUM_TRANSFER_SENT_DETAILS_TRANSFER_STATUS_DELETING',
        Expired: 'PREMIUM_TRANSFER_SENT_DETAILS_TRANSFER_STATUS_EXPIRED',
        Suspended: 'PREMIUM_TRANSFER_SENT_DETAILS_TRANSFER_STATUS_SUSPENDED',
    };

    public static splashScreen = {
        visitsCountToShow: 10,
        maxDelayBetween2Visits: {
            value: 2,
            unit: 'weeks' as moment.unitOfTime.Base
        }
    }

    public static globalRegion = 'global';

    public static resourceTagValues = {
        applicationID: 'LambdastaticSmashApp',
    };
    public static smashQueryParams = {
        themeId: 'theme_id',
        themeLanguage: 'theme_language',
        themeCountryCode: 'theme_country_code',
    };

    public static videoProviders: { [key: string]: Plyr.Provider } = {
        Youtube: 'youtube',
        Vimeo: 'vimeo',
        Video: 'html5',
    };

    public static encodedInfosRedirectionKey = 'infos';
    public static maxUploadSizeFreemium = 2000000000;
    public static oneDayInSecond = 86400;
    public static regexpDomainCreation = /^([a-z0-9-_]{1,62})$/;
    public static captchaKey = environment.captchaKey;
    public static accountTypes = {
        developer: 'developer',
        premium: 'premium',
    };
    public static previewOptions = [
        { value: 'Full', translation: 'UPLOADER_CONFIG_SMASH_PREVIEW_NOTIFICATION_ENABLED' },
        { value: 'None', translation: 'UPLOADER_CONFIG_SMASH_PREVIEW_NOTIFICATION_DISABLED' },
    ];
    public static notificationOptions = [
        { value: 'All', translation: 'UPLOADER_CONFIG_SMASH_PREVIEW_NOTIFICATION_ENABLED' },
        { value: 'None', translation: 'UPLOADER_CONFIG_SMASH_PREVIEW_NOTIFICATION_DISABLED' },
    ];
    public static regexpPromotionLink = /^(https?:\/\/.+)|(mailto:.+)$/;
    public static passwordPattern = '^.{4,}$';
    public static passwordStrengthRegexps = {
        strong: /^(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/,
        medium: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,
    };
    public static VATRegexp = /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/;
    public static regexpCustomUrl = /^(?!.*-\s*[0-9a-zA-Z]{2}\s*$)((?!([\#\)\(+\\\/]+)).)+$/;
    public static signupEmailPattern = /^[a-z0-9_.+-]+@[a-z0-9-]+\.[a-z0-9-.]+$/;
    public static regexpGroupName = /^[^,;]+$/;
    public static regexpYoutube = /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:(?:youtube\.com|youtu.be))(?:\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;
    public static regexpVimeo = /(?:http:|https:|)\/\/(?:player.|www.)?vimeo\.com\/(?:(?:groups\/|showcase\/|album\/)[0-9]+\/videos?\/|channels\/[a-z0-9_.+-]+\/|video\/|embed\/|watch\?\S*v=|v\/)?(\d*)/;
    public static deliveryTypes = {
        Email: 'Email',
        Link: 'Link',
        Slack: 'Slack',
    };
    public static domainLoginRedirection = {
        None: 'None',
        Signin: 'RedirectToSignin',
        Provider: 'RedirectToProvider',
    };
    public static regexpExtractEmail = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;
    public static regexpEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z0-9]{2,20}$/;
    public static regexpEmailWithoutTag = /([a-zA-Z0-9._%-]+)\+?[a-zA-Z0-9._%-]*(@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;
    public static regexpDomainExtension = /\w+((\.[a-z]{2,5})(\.(ninja|rocks|com))?)$/;
    public static regexpExcludeUserSCIM = /^((?!\$scim).)*$/i;
    public static pricingUrls = {
        fr: '/offres',
        en: '/pricing',
        es: '/es/pricing',
        it: '/it/pricing',
        pt: '/pt/pricing',
        de: '/de/pricing',
        fallback: '/pricing',
    };
    public static subscriptionStatus = {
        Active: 'Active',
        Inactive: 'Inactive',
        Pending: 'Pending',
        PaymentRequired: 'Payment Required',
    };

    public static transferStatus = {
        Uploading: 'Uploading',
        Processing: 'Processing',
        Uploaded: 'Uploaded',
        Suspended: 'Suspended',
        Expired: 'Expired',
        Deleted: 'Deleted',
        Deleting: 'Deleting',
    };
    public static previewStatus = {
        None: 'None',
        Full: 'Full',
    };
    public static accessTracking = {
        None: 'None',
        Email: 'Email'
    };
    public static uploaderStep = {
        Landing: 'LANDING',
    };
    public static planGroupIdLength = 42;
    public static errorStatus = {
        uploader: {
            linkAlreadyExists: 'UPLOADER_LINK_ALREADY_EXISTS',
        },
    };
    public static providerPlayerTypes = {
        vimeo: 'Vimeo',
        youtube: 'Youtube',
        html5: 'html5',
    };
    public static administratorRoleName = 'ManagedAdministratorRole';
    public static SCIMRoleName = 'ManagedSCIMRole';
    public static marketerRoleName = 'Marketer';
    public static reporterRoleName = 'Reporter';
    public static urlYoutubeIframeAPI = 'https://www.youtube.com/iframe_api';
    public static urlVimeoIframeAPI = 'https://player.vimeo.com/api/player.js';
    public static callToActionsList = [
        { value: 'Buy', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_BUY' },
        { value: 'Discover', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_DISCOVER' },
        { value: 'Order', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_ORDER' },
        { value: 'Signup', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_REGISTER' },
        { value: 'Subscribe', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_SUBSCRIBE' },
        { value: 'Contact', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_CONTACT_US' },
        { value: 'Download', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_DOWNLOAD' },
        { value: 'Share', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_SHARE' },
        { value: 'Like', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_I_LIKE' },
        { value: 'Test', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_TRY' },
        { value: 'JoinIn', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_JOIN_IN' },
        { value: 'Play', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_PLAY' },
        { value: 'AddToPlaylist', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_ADD_TO_PLAYLIST' },
        { value: 'FollowUs', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_FOLLOW_US' },
        { value: 'DiscoverSmash', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_DISCOVER_SMASH' },
        { value: 'Read', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_READ' },
        { value: 'Watch', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_WATCH' },
        { value: 'LearnMore', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_KNOW_MORE' },
        { value: 'Book', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_BOOK' },
        { value: 'Act', translation: 'PREMIUM_PROMOTION_OPTION_CALL_TO_ACTION_ACT' },
    ];
    public static refreshTokenStatus = {
        Processing: 'Processing',
        Success: 'Success',
        Failure: 'Failure',
    };
    public static Unknown = 'Unknown';
    public static Currencies = {
        eur: {
            iso3: 'eur' as ListCountriesOutput['countries'][0]['currency'],
            symbol: '€',
            displayBeforePrice: false,
        },
        usd: {
            iso3: 'usd' as ListCountriesOutput['countries'][0]['currency'],
            symbol: 'US$',
            displayBeforePrice: true,
        },
        inr: {
            iso3: 'inr' as ListCountriesOutput['countries'][0]['currency'],
            symbol: '₹',
            displayBeforePrice: true,
        },
        aud: {
            iso3: 'aud' as ListCountriesOutput['countries'][0]['currency'],
            symbol: 'A$',
            displayBeforePrice: true,
        },
        brl: {
            iso3: 'brl' as ListCountriesOutput['countries'][0]['currency'],
            symbol: 'R$ ',
            displayBeforePrice: true,
        },
        cad: {
            iso3: 'cad' as ListCountriesOutput['countries'][0]['currency'],
            symbol: 'C$',
            displayBeforePrice: true,
        },
        mxn: {
            iso3: 'mxn' as ListCountriesOutput['countries'][0]['currency'],
            symbol: 'MXN$',
            displayBeforePrice: true,
        },
        try: {
            iso3: 'try' as ListCountriesOutput['countries'][0]['currency'],
            symbol: '₺',
            displayBeforePrice: true,
        },
        gbp: {
            iso3: 'gbp' as ListCountriesOutput['countries'][0]['currency'],
            symbol: '£',
            displayBeforePrice: true,
        },
        cop: {
            iso3: 'cop' as ListCountriesOutput['countries'][0]['currency'],
            symbol: 'COP$ ',
            displayBeforePrice: true,
        },
        nok: {
            iso3: 'nok' as ListCountriesOutput['countries'][0]['currency'],
            symbol: 'kr ',
            displayBeforePrice: true,
        },
        pen: {
            iso3: 'pen' as ListCountriesOutput['countries'][0]['currency'],
            symbol: 'S/ ',
            displayBeforePrice: true,
        },
        zar: {
            iso3: 'zar' as ListCountriesOutput['countries'][0]['currency'],
            symbol: 'R ',
            displayBeforePrice: true,
        }
    };

    public static teamConfig = {
        authenticationMode: {
            anonymous: 'Anonymous',
            authentified: 'Authentified',
        },
        rulesNames: {
            wildcard: 'Wildcard',
            email: 'Email'
        }
    };
    public static planFreemiumID = 'SMASH_FREEMIUM';
    public static authenticationPatternEmailWithAt = /^@[a-z0-9-]+\.[a-z0-9-]+\.*[a-z0-9-]*$/;
    public static authenticationPatternEmail = /^[a-z0-9-]+\.[a-z0-9-]+\.*[a-z0-9-]*$/;
    public static authenticationMode = {
        anonymous: 'Anonymous',
        authentified: 'Authentified',
    };
    public static fileTypes = {
        Image: 'image',
        Video: 'Video',
        Document: 'Document',
    };
    public static countryCodes = {
        FR: 'FR',
    };
    public static countryCodesVAT = ['FR', 'GP', 'MC', 'RE', 'MQ'];

    public static paymentType = {
        CARD: 'card',
        PAYPAL: 'paypal',
    };
    public static paymentStatus = {
        SUCCESS: 'Success',
        FAILURE: 'Failure',
    };
    // FIX ME in use?
    public static delayScale = [
        {
            minSize: 0, // 0
            maxSize: 2000000000, // 2G
            delayPerGo: 0,
            minDelay: 0,
        },
        {
            minSize: 2000000001, // 2G
            maxSize: 5000000000, // 5G
            delayPerGo: 3600, // 60min par G
            minDelay: 3600 * 21, // 21 heures
        },
        {
            minSize: 5000000001, // 5G
            maxSize: 10000000000, // 10G
            delayPerGo: 3600, // 60min par G
            minDelay: 3600 * 24, // 24 heures => 29 heaures
        },
        {
            minSize: 10000000001, // 10G
            maxSize: 20000000000, // 20G
            delayPerGo: 3600, // 60min par G
            minDelay: 3600 * 29, // 29 heures => 39 heures
        },
        {
            minSize: 20000000001, // 20G
            maxSize: 50000000000, // 50G
            delayPerGo: 0, // 0min par G
            minDelay: 3600 * 24 * 2, // 2 jours
        },
        {
            minSize: 50000000001, // 50G
            maxSize: 100000000000, // 100G
            delayPerGo: 0, // 0min par G
            minDelay: 3600 * 24 * 3, // 3 jours
        },
        {
            minSize: 100000000001, // 100G
            maxSize: Infinity,
            delayPerGo: 0, // 0min par G
            minDelay: 3600 * 24 * 7, // 7 jours
        },
    ];
    public static supportedLanguages = [
        {
            id: 'fr',
            name: 'Français'
        },
        {
            id: 'de',
            name: 'Deutsch'
        },
        {
            id: 'en',
            name: 'English'
        },
        {
            id: 'es',
            name: 'Español'
        },
        {
            id: 'it',
            name: 'Italiano'
        },
        {
            id: 'pt',
            name: 'Português'
        }
    ];
    public static transferEventStatus = {
        view: 'View',
        download: 'Download',
    };
    public static transferEventResource = {
        file: 'File',
        transfer: 'Transfer',
    };
    public static uploaderErrorType = {
        emailForbidden: 'EmailNotAllowedError',
        invalidSubscription: 'InvalidSubscriptionError',
        invalidAvailabilityDurationError: 'InvalidAvailabilityDurationError',
        passwordRequired: 'PasswordRequiredError',
        customUrlAlreadyInUse: 'CustomUrlAlreadyInUseError',
    };
    public static userStatus = {
        enabled: 'Enabled',
        disabled: 'Disabled',
    };
    public static ssoProviderStatus = {
        enabled: 'Enabled',
        disabled: 'Disabled',
    };
    // FIX ME in use?
    public static targetCountryAds = ['GB', 'US', 'CA', 'AU', 'SG', 'PH', 'IN', 'BR', 'MX', 'CO', 'AR', 'PE', 'CL', 'EC', 'BO'];
    public static customizationFallback = {
        logo: {
            waveColor: '#FFFFFF',
            sourceUrl: 'https://fromsmash.com/assets/img/smash-logo/png/icono-chip-full-white.png',
            thumnail: 'https://fromsmash.com/assets/img/smash-logo/png/icono-chip-full-white.png',
            type: 'Image'
        },
        background: {
            sourceUrl: 'https://asset.fromsmash.co/media/email/background_red', // TODO change to black
            type: 'Image'
        },
    };

    // FIX ME in use?
    public static REGIONS = {
        'eu-west-3': 'FR',
        'eu-west-2': 'GB',
        'eu-west-1': 'IE',
        'eu-central-1': 'DE',
        'ca-central-1': 'CA',
        'us-east-1': 'US',
        'us-east-2': 'US',
        'us-west-1': 'US',
        'us-west-2': 'US',
    };

    public static europeanRegions = ['eu-west-1', 'eu-west-2', 'eu-west-3', 'eu-central-1'];

    public static SsoParams = {
        types: {
            SAML: 'Saml'
        },
        name: 'primarySsoParams',
    };

    public static signinMode = {
        standard: 'Standard',
        sso: 'SSO',
    };

    public static domainType = {
        CNAME: 'CName'
    };

    public static SAMLRequestKey = 'SAMLRequest';

    public static transferMode = {
        dropbox: 'Dropbox',
        transfer: 'Transfer',
    };

    public static defaultManagedDomainSettings: DomainSettings = {
        transferMode: {
            active: true,
            default: 'Transfer',
            options: ['Transfer'],
        },
        transfer: {
            availabilityDuration: {
                active: true,
                default: 7,
                min: 1,
                max: 14
            },
            preview: {
                options: [
                    Constant.previewStatus.Full,
                    Constant.previewStatus.None,
                ],
                active: true,
                default: Constant.previewStatus.Full,
            },
            delivery: {
                options: [
                    Constant.deliveryTypes.Email,
                    Constant.deliveryTypes.Link,
                ],
                active: true,
                default: Constant.deliveryTypes.Email,
            },
            password: {
                active: true,
                minLength: 4,
                required: false,
            },
            customization: {
                active: true
            },
            notification: {
                sender: { active: true, enabled: true },
                link: { active: true, enabled: true },
                receiver: { active: true, enabled: true },
                download: { active: true, enabled: true },
                noDownload: { active: true, enabled: true },

            },
            accessTracking: {
                active: true,
                default: 'None',
            },
            promotion: {
                active: true
            }
        },
        redirection: {
            active: true,
            value: Constant.domainLoginRedirection.None,
            options: [
                Constant.domainLoginRedirection.None,
                Constant.domainLoginRedirection.Signin,
                Constant.domainLoginRedirection.Provider,
            ]
        }
    };

    public static defaultPremiumDomainSettings: GetRecordOutput['record']['options'] = {
        transferMode: {
            active: true,
            default: 'Transfer',
            options: ['Dropbox', 'Transfer'],
        },
        transfer: {
            availabilityDuration: {
                active: true,
                default: 7,
                max: 30,
                min: 1,
            },
            preview: {
                active: true,
                default: 'Full',
                options: [
                    'None',
                    'Full',
                ]
            },
            delivery: {
                active: true,
                default: 'Email',
                options: [
                    'Link',
                    'Email',
                ]
            },
            password: {
                active: true,
                required: false,
                minLength: 4
            },
            customization: {
                active: true,
            },
            notification: {
                sender: { active: true, enabled: true },
                link: { active: true, enabled: true },
                receiver: { active: true, enabled: true },
                download: { active: true, enabled: true },
                noDownload: { active: true, enabled: true },
            },
            accessTracking: {
                active: false,
                default: 'None',
            },
            promotion: {
                active: true,
            },
        },
        dropbox: {
            maxTransferCount: {
                active: true,
                default: undefined,
                min: 1,
            },
            availabilityDuration: {
                active: true,
                default: 7 * 86400,
                max: 365 * 86400,
                min: 86400,
            },
            maxBytesUploaded: {
                active: true,
                default: undefined,
                max: undefined,
                min: 1,
            },
            transfer: {
                notification: {
                    sender: { active: true, enabled: true },
                    receiver: { active: true, enabled: true },
                    download: { active: true, enabled: true },
                    noDownload: { active: true, enabled: true },
                },
                preview: {
                    active: true,
                    default: 'Full',
                    options: [
                        'None',
                        'Full',
                    ]
                },
                password: {
                    active: true,
                    required: false,
                    minLength: 4
                },
                availabilityDuration: {
                    active: true,
                    default: 7 * 86400,
                    max: 30 * 86400,
                    min: 86400,
                },
            }
        },
        redirection: {
            active: true,
            value: 'None',
            options: [
                'RedirectToProvider',
                'RedirectToSignin',
                'None',
            ]
        }
    };

    public static osType = {
        iOS: 'iOS',
        android: 'Android',
    };

    // NOT USED
    public static squareSpaceUrls = {
        apps: {
            en: 'https://en.fromsmash.com/smash-app',
            fr: 'https://fr.fromsmash.com/apps-mobile',
            es: 'https://es.fromsmash.com/aplicaciones-moviles',
            de: 'https://en.fromsmash.com/smash-app',
            it: 'https://en.fromsmash.com/smash-app',
            pt: 'https://en.fromsmash.com/smash-app',
            default: 'https://en.fromsmash.com/smash-app',
        },
        mobile: {
            en: 'https://en.fromsmash.com',
            fr: 'https://fr.fromsmash.com',
            es: 'https://en.fromsmash.com',
            de: 'https://en.fromsmash.com',
            it: 'https://en.fromsmash.com',
            pt: 'https://en.fromsmash.com',
            default: 'https://en.fromsmash.com',
        }
    };

    public static eventGTM = {
        SUBSCRIPTION: 'subscription',
        UPDATE_SUBSCRIPTION: 'update_subscription',
        START_TRANSFER: 'start_transfer',
        FIRST_TIME_SENDER: 'first_time_sender',
        FIRST_TIME_RECEIVER: 'first_time_receiver',
        TRANSFER_INITIATED: 'transfer_initiated',
        TRANSFER_CANCELLED: 'transfer_cancelled',
        TRANSFER_LOCKED: 'transfer_locked',
        SMASH_AGAIN: 'smash_again',
        SIGNIN: 'signin',
        EXTERNAL_PROMOTION_DISPLAYED: 'external_promotion_displayed',
        INTERNAL_PROMOTION_DISPLAYED: 'internal_promotion_displayed',
        CLICK_PROMOTION_CTA: 'click_promotion_cta',
        CLICK_ADD_PROMOTION_FREEMIUM: 'click_add_promotion_freemium',
        CLICK_ADD_BACKGROUND_LOGO_FREEMIUM: 'click_add_background_logo_freemium',
        CLICK_BACKDROP_MODAL_SIZE_PREMIUM: 'click_backdrop_modal_size_premium',
        CLICK_REDIRECTION_CAMPAIGN: 'click_campaign_link',
        VIEW_VIDEO_COMPLETION: 'view_video_completion',
        CLICK_THEME_REVEAL_CTA: 'click_theme_reveal_cta',
        DISPLAY_THEME_REVEAL: 'display_theme_reveal',
        CONSENT_UPDATE: 'consent_update',
    };

    public static languageFallback = 'en';

    public static domainRegexp = /(?:https?:\/\/)?(?:www\.)?(?:[[0-9A-Za-z\-]+\.)?([[0-9A-Za-z\-]+\.[[0-9A-Za-z\-\.]+)/;
    public static httpUrlRegexp = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{1,}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

    public static redirection = {
        main: 'fromsmash',
        unsupported: '/unsupported',
        serviceUnavailable: '/service-unavailable'
    };

    public static serviceErrors = {
        discovery: 'DISCOVERY_ERROR',
        domain: 'DOMAIN_ERROR'
    };

    public static mfaAuthenticatorApplication = {
        issuer: 'https://fromsmash.com',
        name: 'Smash',
    }
}
